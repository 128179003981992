import createI18n from 'gettext.js';

import fi from './locale/fi/message.json';

const i18n = createI18n();

i18n.setMessages('messages', 'fi', fi);

i18n.setLocale('fi');

export default i18n;
